//
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import SplitText from "../../utils/Split3.min";
import cn from "classnames";
import useOnScreen from "../../hooks/useOnScreen";
import "./style.scss";
//
//
export default function About() {
  // intersection Observer  **
  const ref = useRef();
  const [reveal, setReveal] = useState(false);
  //Here we will pass the intersectionObserver from the file,  after defining the ref, add it to the <p id="headline">
  const onScreen = useOnScreen(ref);
  // 2 useEFFECT IntersectionObserver
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  //-------------------------
  //
  //

  //1 useEffect GSAP  **
  useEffect(() => {
    //
    if (reveal) {
      const split = new SplitText("#headline", {
        //   anim text
        type: "lines",
      });

      gsap.to(split.lines, {
        duration: 3, //1 is really fast, 3 is slow
        y: -20, //
        opacity: 1,
        stagger: 0.1,
        ease: "power2",
      });
      //
      //
    }
  }, [reveal]);

  //
  //
  return (
    <section className={cn("about-section")} data-scroll-section>
      {/* <SectionHeader title="about" /> */}
      <p
        ref={ref}
        id="headline"
        className={cn({ "is-reveal": reveal })}
        data-scroll
        data-scroll-speed="-4"
        data-scroll-direction="horizontal"
      >
        Nadia Mariduena is a Frontend Developer with experience in Reactjs
        /Nextjs / Vite / Typescript / NodeJS/ Express / MongoDB / Figma. Other
        experiences: CommerceJs / Stripe / Strapi / Threejs /WebGL, Modeling
        with Blender.
      </p>
    </section>
  );
}
