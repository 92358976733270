import React from "react";
import "./style.scss";

//
//
import img1 from "../../img/pexels_photo_low-res.jpeg";

const Index = () => {
  return (
    <section id="svg-wrapper-gallery" data-scroll-section>
      {" "}
      <div className="wrapper-bio-top-absolute">
        <div className="header-container" data-scroll-section>
          <h1
            id="header-text"
            className="header-text"
            data-scroll
            data-scroll-speed="1"
          >
            .WELCOME.
          </h1>
        </div>
      </div>
      <div className="svg-container-gallery">
        <div className="svg-img-column">
          {/*  */}
          <svg
            version="1.1"
            viewBox="0 0 500 500"
            preserveAspectRatio="xMinYMin meet"
            className="svg-content"
          >
            <defs>
              <clipPath id="shape">
                <path stroke="red" strokeWidth="3" fill="url(#shape">
                  <animate
                    attributeName="d"
                    dur="5s"
                    repeatCount="indefinite"
                    values="


                M476,299Q454,348,426.5,392.5Q399,437,347.5,444Q296,451,246.5,467.5Q197,484,147,463Q97,442,82.5,390Q68,338,55.5,294Q43,250,44.5,201Q46,152,85.5,122.5Q125,93,162.5,61.5Q200,30,248,39Q296,48,340.5,64.5Q385,81,423,114.5Q461,148,479.5,199Q498,250,476,299Z;

           M453.5,289Q413,328,399.5,374Q386,420,331.5,393.5Q277,367,239,415.5Q201,464,152.5,449Q104,434,129,365Q154,296,141,273Q128,250,108,211Q88,172,137,171Q186,170,198.5,124.5Q211,79,244,104.5Q277,130,310,131.5Q343,133,388.5,147.5Q434,162,464,206Q494,250,453.5,289Z;


           M476,299Q454,348,426.5,392.5Q399,437,347.5,444Q296,451,246.5,467.5Q197,484,147,463Q97,442,82.5,390Q68,338,55.5,294Q43,250,44.5,201Q46,152,85.5,122.5Q125,93,162.5,61.5Q200,30,248,39Q296,48,340.5,64.5Q385,81,423,114.5Q461,148,479.5,199Q498,250,476,299Z;


"
                  />
                </path>
              </clipPath>
            </defs>
            {/*  */}
            <image
              className="img-box"
              x="0"
              y="0"
              width="100%"
              height="100%"
              clipPath="url(#shape)"
              href={img1}
              preserveAspectRatio="none"
            ></image>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default Index;
