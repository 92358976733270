const photos = [
  "https://images.pexels.com/photos/10669649/pexels-photo-10669649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  "./img/pexels_cottonbro_1.jpg",
];
export default photos;

/*


const photos = [
  "https://images.unsplash.com/photo-1598838073192-05c942ede858?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=934&q=100",
  "https://images.unsplash.com/photo-1552248524-10d9a7e4841c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=881&q=100",
];

*/
