//0
import { useEffect } from "react";
import "locomotive-scroll/src/locomotive-scroll.scss";
import LocomotiveScroll from "locomotive-scroll";
//
//
//
//7 this import will be added when handling the Gallery slider logic
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//8 register the scroll
gsap.registerPlugin(ScrollTrigger);
//

//1 add the start
export default function useLocoScroll(start) {
  //2
  useEffect(() => {
    if (!start) return;

    //
    //5 so here we grab the parent container, we will pass this ref
    // in step6
    const scrollEl = document.querySelector("#main-container"); //   id="main-container" in Home.jsx
    //4
    let locoScroll = new LocomotiveScroll({
      //here is going to be the element and the element is the
      // container we added to the parent div:  'main-container'
      //6
      el: scrollEl,
      smooth: true,
      touchMultiplier: 2, //Multiply touch action to scroll faster than finger movement.
      multiplier: 1.5, //3 is faster 2, medium, 1 slow
      class: "is-reveal", //when the element will get into the viewport
      //   each of them will get its reveal class, and then  we could animate the heigh
    });
    //3

    //9 this one will be added when handling the Gallery slider logic
    locoScroll.on("scroll", () => {
      ScrollTrigger.update();
    });

    //--------- 10
    ScrollTrigger.scrollerProxy(scrollEl, {
      scrollTop(value) {
        //here we check if we are locomotive scrolling
        ///
        if (locoScroll) {
          //if there is some lenght ?, then show locoScroll.scrollTo(value, 0, 0)
          //but if there is no : scroll, show  locoScroll.scroll.instance.scroll.y;
          return arguments.length
            ? locoScroll.scrollTo(value, 0, 0)
            : locoScroll.scroll.instance.scroll.y;
        }
        return null;
      },
      //11
      scrollLeft(value) {
        if (locoScroll) {
          //if there is some lenght ?, then show locoScroll.scrollTo(value, 0, 0)
          //but if there is no : scroll, show  locoScroll.scroll.instance.scroll.y;
          return arguments.length
            ? locoScroll.scrollTo(value, 0, 0)
            : locoScroll.scroll.instance.scroll.x;
        }
        return null;
      },
    });

    //12 updating the fucntion
    const isUpdate = () => {
      // If there is locoScroll the locoScroll update
      if (locoScroll) {
        locoScroll.update();
      }
    };
    //13 the event listener to SCROLL TRIGGER
    ScrollTrigger.addEventListener("refresh", isUpdate);
    ScrollTrigger.refresh();
    //
    //

    // 14 cleaning of the code above when unmount
    return () => {
      if (locoScroll) {
        ScrollTrigger.removeEventListener("refresh", isUpdate);
        //15
        locoScroll.destroy();
        locoScroll = null;
      }
    };
    //
  }, [start]);
}
