export const dataModal = [
  {
    id: 1,
    url: "https://github.com/nadiamariduena/eshop-strapi-duplicate/",
    title: "Kookaii e-shop",
    pagination: "1 / 4",
    description:
      "Over the course of a year, I worked on an exercise project: an e-commerce website. I used a tech stack including React, Redux, MySQL, Strapi, Stripe, Apache, and Framer Motion to build dynamic interfaces, manage data, process payments securely, and enhance user experience with animations.",

    imageModal: "../../imgModal/strapiShop.gif",
    image: "../../imgModal/e-shop-str.jpg",

    stackList: [
      {
        id: 0,
        label: "React, Redux",
      },
      {
        id: 1,
        label: "Strapi",
      },
      {
        id: 2,
        label: "Apache, Mysql",
      },
      {
        id: 3,
        label: "framer",
      },
    ],
  },

  {
    id: 1,
    url: "https://nemu-mobile-store.netlify.app/",
    title: "Nemu (API)",
    pagination: "2 / 4",
    description:
      "The app is capable of fetching product data from the commercejs API. In addition to these functionalities, the app provides a secure payment system using the testing API from both Stripe and Commercejs.  Although the payment transactions are not processed, users receive an email confirmation indicating that their payment has been accepted. ",

    imageModal: "../../imgModal/nemu-phone-store.gif",
    image: "../../imgModal/nemu-phone-app-low.jpg",

    stackList: [
      {
        id: 0,
        label: "React",
      },
      {
        id: 1,
        label: "Stripe",
      },
      {
        id: 2,
        label: "Commercejs",
      },
      {
        id: 3,
        label: "Framer, MUI",
      },
    ],
  },
  {
    id: 2,
    url: "https://mubii-app-nadiamariduena.netlify.app/",
    title: "Movii (API)",
    pagination: "3 / 4",
    description:
      "The app is designed to store user preferences using localStorage and fetch data from The Movie Database API, allowing it to provide personalized movie recommendations to users. I decided to give the app a unique touch by incorporating a vintage school design, even though it may not be the most popular choice for this type of application. Despite this, the app offers a seamless user experience for movie enthusiasts everywhere.",

    imageModal: "../../imgModal/movii-app.gif",
    image: "../../imgModal/movii-bg-img.jpg",

    stackList: [
      {
        id: 0,
        label: "React",
      },
      {
        id: 1,
        label: "The movie Api",
      },
      {
        id: 2,
        label: "Framer",
      },
      {
        id: 3,
        label: "Styled components",
      },
    ],
  },
  {
    id: 3,
    // url: "https://mubii-app-nadiamariduena.netlify.app/",
    title: "SocioPathdia",
    pagination: "4 / 4",
    description:
      "Fake Social Media application using the MERN stack, encompassing MongoDB for data storage, Express.js for server-side routing, React for the frontend interface, and Node.js for backend operations. The application features user authentication functionalities, including login and registration capabilities. It implements all CRUD operations, ensuring comprehensive data management.",

    imageModal: "../../imgModal/fake-socialmedia.gif",
    image: "../../imgModal/fakeSocial.jpg",

    stackList: [
      {
        id: 0,
        label: "MongoDB",
      },
      {
        id: 1,
        label: "Express",
      },
      {
        id: 2,
        label: "React",
      },
      {
        id: 3,
        label: "Framer",
      },
    ],
  },
  {
    id: 4,
    url: "https://meyoko.com/",
    title: "Meyoko's Folio",
    pagination: "5 / 5",
    description:
      "I Developed this artist portfolio with React, Framer, GSAP, shaders, and Sass, blending simplicity with sophistication to mirror the artist's psychedelic aesthetic. Intentionally designed to resonate with the artist's unique style and showcase her vibrant artworks.",

    imageModal: "../../imgModal/meyoko-folio.gif",
    image: "../../imgModal/meyoko-site-blacl.jpg",

    stackList: [
      {
        id: 0,
        label: "React",
      },
      {
        id: 1,
        label: "Framer",
      },
      {
        id: 1,
        label: "GSAP",
      },
    ],
  },
];
