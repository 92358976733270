// 1 anim text
import React, { useEffect, useRef, useState } from "react";
// import SectionHeader from "../SectionHeader";
import gsap from "gsap";
import cn from "classnames";
import SplitText from "../../utils/Split3.min";
import useOnScreen from "../../hooks/useOnScreen";
//

import "./style.scss";

const Index = () => {
  // intersection Observer  **
  const ref = useRef(null);
  const [reveal, setReveal] = useState(false);
  //Here we will pass the intersectionObserver from the file,  after defining the ref, add it to the <p id="headline">
  const onScreen = useOnScreen(ref);
  // 2 useEFFECT IntersectionObserver
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  //-------------------------

  //
  // 3 anim text
  useEffect(() => {
    if (reveal) {
      const split = new SplitText("#location-text", {
        // 4 anim text
        type: "lines",
        linesClass: "lineChildren",
      });
      // 7
      const splitParent = new SplitText("#location-text", {
        // 4 anim text
        type: "lines",
        linesClass: "lineParent",
      });

      // step 5 is in the header styles
      // 6
      gsap.fromTo(
        split.lines,
        { y: 200 },
        {
          duration: 2, //1 is really fast, 3 is slow
          y: 0,
          stagger: 0.1, //the time until the second line appear
          ease: "power2",
        }
      );
      //
      //
    }
  }, [reveal]);
  //

  return (
    <section className="thankyou-container" data-scroll-section>
      {/* <SectionHeader title="See you soon!!" /> */}

      <h1
        // the cn or classname, contains 2 classes, you could use template literals
        // but since this classes contains a lot of logic, you use the cn
        className={cn("location", { "is-reveal": reveal })}
        id="location-text"
        ref={ref}
      >
        Thank <span className="sp-thank">you for</span>watching
      </h1>
    </section>
  );
};

export default Index;

/*


export default function Footer() {
  // intersection Observer  **
  const ref = useRef(null);
  const [reveal, setReveal] = useState(false);
  //Here we will pass the intersectionObserver from the file,  after defining the ref, add it to the <p id="headline">
  const onScreen = useOnScreen(ref);
  // 2 useEFFECT IntersectionObserver
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  //-------------------------


  //
  // 3 anim text
  useEffect(() => {
    if (reveal) {
      const split = new SplitText("#location-text", {
        // 4 anim text
        type: "lines",
        linesClass: "lineChildren",
      });
      // 7
      const splitParent = new SplitText("#location-text", {
        // 4 anim text
        type: "lines",
        linesClass: "lineParent",
      });

      // step 5 is in the header styles
      // 6
      gsap.fromTo(
        split.lines,
        { y: 200 },
        {
          duration: 2, //1 is really fast, 3 is slow
          y: 0,
          stagger: 0.1, //the time until the second line appear
          ease: "power2",
        }
      );
      //
      //
    }
  }, [reveal]);
  //

  return (
    <section className="footer" data-scroll-section>
      <SectionHeader title="See you soon!!" />

      <h1
        // the cn or classname, contains 2 classes, you could use template literals
        // but since this classes contains a lot of logic, you use the cn
        className={cn("location", { "is-reveal": reveal })}
        id="location-text"
        ref={ref}
      >
        Thank you
      </h1>
    </section>
  );
}





*/
