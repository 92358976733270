import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
//pages
import Home from "./containers/Home";
//

//

const App = () => {
  /*




  */
  return (
    <AnimatePresence>
      <Router>
        <div className="page">
          <Switch>
            <Route path="/" exact component={Home} />

            <Route path="/*" component={Home} />
          </Switch>
        </div>
      </Router>
    </AnimatePresence>
  );
};

export default App;
