import React, { useState, useRef, useEffect } from "react";
import "../styles/home.scss";
//

// import Navbar from "../components/Navbar";
import Navigation from "../components/Navigation";
import Thankyou from "../components/Thankyou";

import Featured from "../components/Featured";
import About from "../components/About";
import SvgContainer from "../components/HolaCont";
// import Footer from "../components/Footer";
//

import useLocoScroll from "../hooks/useLocoScroll";

const Home = () => {
  //
  // by default its going to be true, so its going
  // to appear from the beginning
  const [preloader, setPreloader] = useState(true);
  // if you add false, the overlay black will not appear
  // you can turn it to false when adding the text animations
  // as it can be annoying when testing.
  //

  //
  useLocoScroll(!preloader);

  //

  // after 3 seconds the preloader will disappear
  const [timer, setTimer] = useState(1);
  //
  //we will need a ref for the interval, this ref will link the loader to the timer state
  const id = useRef(null);
  //  we can save id in useRef and pass it to child

  const clear = () => {
    window.clearInterval(id.current);
    //after the interval has passed,
    // we will set the preloader to false
    setPreloader(false);
  };

  //
  //

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  }, []);

  //
  //

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);
  /*





  */
  return (
    <>
      {preloader ? (
        <div className="loader-wrapper absolute">
          <h1>Nadia Mariduena</h1>
          <h2>folio</h2>
        </div>
      ) : (
        <div
          className="main-container"
          id="main-container"
          data-scroll-container
        >
          {/* <Navbar />  */}
          <Navigation /> <SvgContainer />
          <About />
          <Featured />
          <Thankyou />
          {/* <Footer /> */}
        </div>
      )}
    </>
  );
};

export default Home;
