import React, { useState } from "react";
// import SectionHeader from "../SectionHeader";
import "./style.scss";
//
import ModalContent from "./ModalContent";
import { dataModal } from "./dataModal";

//
const dataLinkois = [
  {
    id: "0",
    linktitle: "followLinks",
    linkName: "github",
    linkUrl: "https://github.com/nadiamariduena",
  },
  {
    id: "1",
    linktitle: "followLinks",
    linkName: "instagram",
    linkUrl: "https://www.instagram.com/zardozsan/",
  },
  {
    id: "2",
    linktitle: "followLinks",
    linkName: "linkedin",
    linkUrl: "https://www.linkedin.com/in/nadia-mariduena/",
  },
];
//
const Index = () => {
  //
  const [openModalTwo, setOpenModalTwo] = useState(false);

  //
  return (
    <>
      <header className="navigation">
        <nav>
          <div className="grid-1-nav">
            <div className="top-grid-1-nav">
              2024
              {/* <div className="email">
              <a
                href="https://github.com/zorpas"
                rel="noopener noreferrer"
                target="_blank"
              >
                Github
              </a>
            </div> */}
            </div>
            <div className="left-grid-1-nav">.Nadia Mariduena.</div>
            <div className="right-grid-1-nav">
              <p
                onClick={() => {
                  setOpenModalTwo(true);
                }}
              >
                {" "}
                Folio
              </p>
            </div>
          </div>
        </nav>

        <div className="grid-2-nav">
          {/* 1 */}
          <div className="social-1">
            <div className="folio-3D">
              <button>&#x2726;</button>
            </div>
          </div>
          {/* 💟  2*/}
          <div className="social-2">
            <div className="copyright-social">
              <p>&#xa9; nadiamariduena</p>
            </div>
          </div>
          {/* 3 */}
          <div className="social-3">
            <div className="social-icons-container">
              {dataLinkois.map((eFollow) => (
                <div key={eFollow.id} className="social-icon">
                  <button>
                    {" "}
                    <a
                      style={{ color: "black" }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={eFollow.linkUrl}
                    >
                      &#x2726;
                    </a>
                  </button>{" "}
                </div>
              ))}

              {/* <div className="social-icon">

              </div>
              <div className="social-icon">
                <button>&#x2726;</button>
              </div>
              <div className="social-icon">
                <button>&#x2726;</button>
              </div> */}
            </div>
          </div>
          {/* ----- */}
        </div>
      </header>
      {openModalTwo && (
        <ModalContent closingModalTwo={setOpenModalTwo} slides={dataModal} />
      )}
    </>
  );
};

export default Index;

/*


 <header className="navigation">

        <ul>
          <li>
            <a
              className="linkedin"
              href="https://www.linkedin.com/in/nadiamurillo-m/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Linkedin
            </a>
          </li>

          <li className="email">
            <a
              className="copy"
              href="https://github.com/zorpas"
              rel="noopener noreferrer"
              target="_blank"
            >
              Github
            </a>
          </li>
          <li
            className="portfolio"
            onClick={() => {
              setOpenModalTwo(true);
            }}
          >
            portfolio
          </li>
          <li className="copy">Copyright 2022 </li>
        </ul>
      </header>
      {openModalTwo && (
        <ModalContent closingModalTwo={setOpenModalTwo} slides={dataModal} />
      )}

*/
