import React from "react";
import photos from "../../data";

//

import "./style.scss";

const Index = () => {
  //
  //
  const [firstImage, secondImage] = photos;
  //

  //for the image reveal class to work.check the min 12:40
  // you will see that you need the hooke that is being imported inside the containers/Home.jsx
  //
  return (
    <>
      <section className="featured-section" data-scroll-section>
        <div className="featured-row-layout ">
          <h6>001</h6>

          <img src={firstImage} alt={firstImage} data-scroll />
        </div>
        <div className="featured-column-layout">
          <h6>002</h6>
          <img src={secondImage} data-scroll alt={secondImage} />
        </div>
      </section>
    </>
  );
};

export default Index;
