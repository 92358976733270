import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
//
import { HiArrowLongRight } from "react-icons/hi2";
import { HiArrowLongLeft } from "react-icons/hi2";
import { AiOutlineClose } from "react-icons/ai";

//

//https://react-icons.github.io/react-icons/search?q=close
//
import { dataModal } from "./dataModal";
import "./modalContent.scss";
//

function ModalContent({ closingModalTwo, slides }) {
  //
  //
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  //
  //

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  // console.log(current);
  //
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  //

  //
  //
  //if our slides, dont have an array and if our array is not an array type
  //  OR || the slides, which is the images, has less or equal than 0,
  // then return null
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  //---------------

  return (
    <>
      <motion.div
        id="modal-overlay-wrapper"
        initial={{ opacity: 0, y: -180 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -180 }}
        transition={{
          type: "spring",
          duration: 1,
          damping: 12,
          delay: 0.08,
        }}
      >
        <div className="modal-overlay-content">
          <div className="overlay">
            {dataModal.map((item, index) => {
              return (
                <>
                  <div
                    className={index === current ? "slide active" : "slide"}
                    key={index}
                  >
                    {index === current && (
                      <div className="wrapper-img-and-text" key={index}>
                        {/* BLOCK 1 */}
                        <div
                          className="block-0"
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${item.image})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {/* */}
                        </div>
                        {/* BLOCK 1 🧡 */}
                        <div className="block1">
                          <div className="block-bar">
                            <div className="container-top-close">
                              <div className="title-box">
                                {/* pagination */}
                                <h1>{item.title}</h1>
                              </div>
                              <div className="contain-btn-close-mobile">
                                <button
                                  className="btn-close-mobi"
                                  onClick={(e) => (
                                    e.preventDefault(), closingModalTwo(false)
                                  )}
                                >
                                  {/*  btn-preview*/}
                                  <AiOutlineClose />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="block_bottom">
                            <div className="block-left">
                              {/* 🤟 */}

                              {item.url ? (
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={item.url}
                                >
                                  <Emblem str="Preview*Preview*" />
                                </a>
                              ) : (
                                <Emblem str="Preview*Preview*" />
                              )}
                              {/* <div class="emblem">Preview*Preview*</div> */}
                              <div className="wrapper-text-items">
                                <h1>ABOUT</h1>
                                <p>{item.description}</p>
                              </div>
                              {/* STACK  */}
                              <div className="ul-items">
                                <h1>STACK:</h1>
                                <div className="ul-container">
                                  {item.stackList.map((p, index) => {
                                    return (
                                      <>
                                        {p.label && (
                                          <>
                                            <ul key={index}>
                                              <li>{p.label},</li>
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              {/* ARROWS */}
                              <div className="footer-modal">
                                <div className="container-arrow">
                                  {" "}
                                  <button onClick={prevSlide}>
                                    <HiArrowLongLeft className="arrow-modal" />
                                  </button>
                                  <button onClick={nextSlide}>
                                    <HiArrowLongRight className="arrow-modal" />
                                  </button>
                                </div>

                                <div className="pagination">
                                  {/* pagination */}
                                  <h1>{item.pagination}</h1>
                                </div>
                              </div>
                            </div>
                            <div className="block-right">
                              {/* IMG  */}

                              <div className="img-box-modal">
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={item.url}
                                >
                                  <img src={item.imageModal} alt={item.title} />
                                </a>
                              </div>
                              {/*   */}

                              {item.url && (
                                <div className="preview-button">
                                  {/* preview btn */}
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={item.url}
                                  >
                                    {" "}
                                    <button className="previewBtn">
                                      Preview
                                    </button>{" "}
                                  </a>
                                </div>
                              )}
                            </div>
                            {/*  */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </motion.div>
    </>
  );
}

const Emblem = ({ str }) => {
  const emblemRef = useRef(null);

  useEffect(() => {
    const init = () => {
      const element = emblemRef.current;
      const text = str ? str : element.innerHTML;
      element.innerHTML = "";

      for (let i = 0; i < text.length; i++) {
        const letter = text[i];
        const span = document.createElement("span");
        const node = document.createTextNode(letter);
        const r = (360 / text.length) * i;
        const x = (Math.PI / text.length).toFixed(0) * i;
        const y = (Math.PI / text.length).toFixed(0) * i;

        span.appendChild(node);
        span.style.webkitTransform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
        span.style.transform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
        element.appendChild(span);
      }
    };

    init();

    // Clean up function
    // return () => {
    //   const element = emblemRef.current;
    //   element.innerHTML = "";
    // };
    return () => {
      if (emblemRef.current) {
        emblemRef.current.innerHTML = "";
      }
    };
  }, [str]);

  return <div ref={emblemRef} className="emblem" />;
};

export default ModalContent;
